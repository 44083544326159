import { FormFieldContext, FormFieldContextState } from 'atomic/legacy/obj.form'
import * as React from 'react'
import { FloatingLabelChildren, FloatingLabelStyled, FloatingLabelWrapperStyled } from './floating-label.component.style'

export interface FloatingLabelProps {
  label: string
}

export interface FloatingLabelState {
  value: string
}

export class FloatingLabel extends React.Component<FloatingLabelProps, FloatingLabelState> {
  render() {
    return (
      <FormFieldContext.Consumer>
        {(formFieldConsumer: FormFieldContextState) => {
          const val = formFieldConsumer && formFieldConsumer.value ? formFieldConsumer.value : ''

          return (
            <FloatingLabelStyled>
              <FloatingLabelChildren>{this.props.children}</FloatingLabelChildren>
              <FloatingLabelWrapperStyled filled={val && val !== ''}>{this.props.label}</FloatingLabelWrapperStyled>
            </FloatingLabelStyled>
          )
        }}
      </FormFieldContext.Consumer>
    )
  }
}
