import axios, { AxiosInstance } from 'axios'
import { buildAccessTokenInterceptor } from './http/interceptors/access-token.interceptor'

export class PostDataSource {
  private instance: AxiosInstance

  constructor(fleuryBaseUrl: string, fleuryClientIdSensedia: string, fleuryBase64Auth: string) {
    this.instance = axios.create({
      baseURL: fleuryBaseUrl
    })

    const accessTokenInterceptor = buildAccessTokenInterceptor(
      fleuryBaseUrl,
      fleuryClientIdSensedia,
      fleuryBase64Auth
    )
    this.instance.interceptors.request.use(accessTokenInterceptor)
  }

  post = (url: string, data?: any) => {
    return this.instance.post(url, data)
  }
}
