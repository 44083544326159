export const getFaqJsonLd = (list: { Question: string; Answer: string }[]) => ({
  '@context': 'https://schema.org',
  '@type': 'FAQPage',
  mainEntity: list.map(item => ({
    '@type': 'Question',
    name: item.Question,
    acceptedAnswer: {
      '@type': 'Answer',
      text: item.Answer
    }
  }))
})
