import { Col, H3, Row } from 'atomic'
import { Button } from 'atomic/atm.button/button.component'
import { FloatingLabel } from 'atomic/legacy/atm.floating-label/floating-label.component'
import { TextAreaField } from 'atomic/legacy/atm.text-field'
import { ForcedFade } from 'atomic/legacy/obj.animation/animation.component.style'
import { Separator } from 'atomic/legacy/obj.box'
import { Form, FormData, Validators } from 'atomic/legacy/obj.form'
import flashDispatcherService from '@root/src/components/legacy/obj.flash-wrapper/flash-dispatcher.service'
import { HttpPostContainer } from '@root/src/components/legacy/obj.http-container/http-post-container.component'
import {
  buildSendEmailRequest,
  sendEmailUrl
} from '@root/src/data/http/request-builder/send-email.request'
import { EventKind, sendEvent } from '@root/src/utils/analytics'
import React, { useState } from 'react'

interface FeedbackFormProps {
  title: string
  pageCategory: string
  pageSlug: string
  emailTo: string
  id?: string
}

interface FeedbackFormData {
  issue: string
}

export const FeedbackForm: React.FunctionComponent<FeedbackFormProps> = props => {
  const eventCategory = `${props.pageCategory} - Feedback`
  const [showForm, setShowForm] = useState(false)
  const [showFeedback, setShowFeedback] = useState(true)

  const handleNoClick = () => {
    sendEvent(EventKind.click, { event_category: eventCategory, event_label: 'no' })
    setShowForm(true)
  }
  const handleYesClick = () => {
    setShowFeedback(false)
    sendEvent(EventKind.click, { event_category: eventCategory, event_label: 'yes' })
    flashDispatcherService.dispatchMessage('Obrigado por seu feedback!', 'success')
  }
  const handleFormSuccess = () => {
    setShowFeedback(false)
    flashDispatcherService.dispatchMessage('Obrigado por seu feedback!', 'success')
  }

  const buildFeedbackEmailContent = (category: string, page: string, message: string) => `
  <h2>Feedback sobre ${category}</h2>
  <p>Página reportada: ${page}</p>
  <p>Feedback: ${message}</p>`

  return (
    showFeedback && (
      <>
        {showForm ? (
          <HttpPostContainer url={sendEmailUrl} onSuccess={handleFormSuccess}>
            {(post, state) => {
              const handleSubmit = async (formData: FormData<FeedbackFormData>) => {
                if (Object.keys(formData.error).length !== 0) {
                  return
                }
                const data = formData.data
                const email = props.emailTo
                const emailSubject = `Feedback Negativo`
                const emailContent = buildFeedbackEmailContent(
                  props.pageCategory,
                  props.pageSlug,
                  data.issue
                )
                const request = buildSendEmailRequest(email, emailSubject, emailContent)
                await post(request)
              }

              return (
                <ForcedFade show={true}>
                  <Form id={props.id} onSubmit={handleSubmit}>
                    <Row mb center="xs">
                      <Col xs>
                        <Form.Field
                          name="issue"
                          validators={[Validators.Required('Campo obrigatório')]}
                        >
                          <FloatingLabel label={'Em que podemos melhorar?'}>
                            <TextAreaField />
                          </FloatingLabel>
                        </Form.Field>
                        <Separator />
                      </Col>
                    </Row>

                    <Row mb center="xs">
                      <Col xs={12} sm={12} md={4} lg={4}>
                        <Button
                          id="button-submit-feedback"
                          type="submit"
                          kind="primary"
                          loading={state.loading}
                          expanded
                        >
                          Enviar
                        </Button>
                        <Separator />
                      </Col>
                    </Row>
                  </Form>
                </ForcedFade>
              )
            }}
          </HttpPostContainer>
        ) : (
          <>
            <Row>
              <Col xs={12}>
                <H3>{props.title}</H3>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={4}>
                <Button id="button-feecback-yes" kind="secondary" expanded onClick={handleYesClick}>
                  Sim
                </Button>
                <Separator />
              </Col>
              <Col xs={12} md={4}>
                <Button id="button-feecback-no" kind="secondary" expanded onClick={handleNoClick}>
                  Não
                </Button>
                <Separator />
              </Col>
            </Row>
          </>
        )}
      </>
    )
  )
}
