import { AccessTokenDataSource } from '@root/src/data/access-token.datasource'
import { AxiosRequestConfig } from 'axios'

type RequestInterceptor = (value: AxiosRequestConfig) => Promise<AxiosRequestConfig>

/** Add access_token and client_id on header */
export const buildAccessTokenInterceptor = (
  fleuryBaseUrl: string,
  fleuryBase64Auth: string,
  fleuryClientIdSensedia: string
) => {
  const interceptor: RequestInterceptor = async (config: AxiosRequestConfig) => {
    config.headers['Content-Type'] = 'application/json'
    config.headers.client_id = fleuryClientIdSensedia

    const accessTokenDataSource: AccessTokenDataSource = new AccessTokenDataSource(
      fleuryBaseUrl,
      fleuryClientIdSensedia,
      fleuryBase64Auth
    )
    const accessToken = await accessTokenDataSource.getAccessToken()
    config.headers.access_token = accessToken

    return config
  }

  return interceptor
}

const getToken = async () => {
  const accessTokenDataSource: AccessTokenDataSource = new AccessTokenDataSource(
    process.env.GATSBY_FLEURY_BASE_URL,
    process.env.GATSBY_FLEURY_CLIENT_ID_SENSEDIA,
    process.env.GATSBY_FLEURY_BASE64_AUTH
  )

  await accessTokenDataSource.getAccessToken()
}

getToken()
