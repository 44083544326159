import axios from 'axios'
import { GrantCodeDataSource } from './grant-code.datasource'
import { getStorage, StorageType } from './storage/storage.provider'

const ACCESS_TOKEN_KEY = 'AccessToken'
const ACCESS_TOKEN_EXPIRATION_KEY = 'AccessTokenExpiration'

interface AccessTokenDTO {
  access_token: string
  refresh_token: string
  token_type: string
  expires_in: number
}

export class AccessTokenDataSource {
  private sessionStorage = getStorage(StorageType.Session)
  private accessTokenDataSource: GrantCodeDataSource
  private fleuryClientIdSensedia: string
  private fleuryBaseUrl: string
  private fleuryBase64Auth: string

  constructor(fleuryBaseUrl: string, fleuryClientIdSensedia: string, fleuryBase64Auth: string) {
    this.fleuryBaseUrl = fleuryBaseUrl
    this.fleuryClientIdSensedia = fleuryClientIdSensedia
    this.fleuryBase64Auth = fleuryBase64Auth
    this.accessTokenDataSource = new GrantCodeDataSource(fleuryBaseUrl, fleuryClientIdSensedia)
  }

  getAccessToken = async () => {
    const accessTokenFromLocalStorage = this.sessionStorage.get(ACCESS_TOKEN_KEY)
    const now = Date.now()
    if (accessTokenFromLocalStorage) {
      const accessTokenExpiration: string = this.sessionStorage.get(ACCESS_TOKEN_EXPIRATION_KEY)
      const accessTokenExpirationDate = Number(accessTokenExpiration)
      const isValidCode = accessTokenExpirationDate - now > 0
      if (isValidCode) {
        return accessTokenFromLocalStorage
      }
    }

    const instance = axios.create({
      baseURL: this.fleuryBaseUrl
    })
    const code = await this.accessTokenDataSource.getGrantCode()
    const response = await instance.post<AccessTokenDTO>(
      '/oauth/access-token',
      {
        code,
        grant_type: 'authorization_code'
      },
      {
        headers: {
          'Content-Type': 'application/json',
          client_id: this.fleuryClientIdSensedia,
          Authorization: `Basic ${this.fleuryBase64Auth}`
        }
      }
    )

    const accessToken = response.data.access_token
    this.sessionStorage.put(ACCESS_TOKEN_KEY, accessToken)
    this.sessionStorage.put(
      ACCESS_TOKEN_EXPIRATION_KEY,
      String(now + response.data.expires_in * 1000)
    )

    return response.data.access_token
  }
}
