import { PostDataSource } from '@root/src/data/post.datasource'
import { graphql, StaticQuery } from 'gatsby'
import * as React from 'react'
import { isNullOrUndefined } from 'util'
import flashDispatcherService from '../obj.flash-wrapper/flash-dispatcher.service'

interface HttpPostContainerProps {
  children: (
    post: (data?: any, mapper?: (response: any) => any) => any,
    result: HttpPostContainerState
  ) => JSX.Element

  onSuccess?: (data: any) => void
  onError?: (error: Error) => void

  /**
   * if no url is setup, then no request is performed.
   */
  url?: string
}

interface HttpPostContainerState {
  data: any
  loading: boolean
  error: any
}

/**
 * Inspired by MutationContainer from react-apollo
 * It performs a POST request and handles `loading`, `error` and `data` (the response)
 */
export class HttpPostContainer extends React.Component<
  HttpPostContainerProps,
  HttpPostContainerState
> {
  constructor(props: HttpPostContainerProps) {
    super(props)
    this.state = {
      data: null,
      loading: false,
      error: null
    }
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          {
            site {
              siteMetadata {
                fleuryBaseUrl
                fleuryBase64Auth
                fleuryClientIdSensedia
              }
            }
          }
        `}
        render={data => {
          const { fleuryBaseUrl, fleuryBase64Auth, fleuryClientIdSensedia } = data.site.siteMetadata
          return this.props.children(
            this.post(fleuryBaseUrl, fleuryBase64Auth, fleuryClientIdSensedia),
            this.state
          )
        }}
      />
    )
  }

  private post = (
    fleuryBaseUrl: string,
    fleuryBase64Auth: string,
    fleuryClientIdSensedia: string
  ) => async (data?: any, mapper?: (response: any) => any) => {
    if (!this.props.url || this.props.url.length === 0) {
      this.setState({
        data: null,
        loading: false,
        error: null
      })
      return
    }

    try {
      this.setState({ loading: true })
      const postDatasource = new PostDataSource(
        fleuryBaseUrl,
        fleuryBase64Auth,
        fleuryClientIdSensedia
      )
      const rawData = await postDatasource.post(this.props.url, data)
      const mappedData = mapper && mapper(rawData)
      const finalData = isNullOrUndefined(mappedData) ? rawData : mappedData
      this.setState({ loading: false, data: finalData }, () => {
        if (this.props.onSuccess) {
          this.props.onSuccess(finalData)
        }
      })
    } catch (error) {
      this.setState({ error, loading: false })
      flashDispatcherService.dispatchMessage(error.message, 'alert')
      if (this.props.onError) {
        this.props.onError(error)
      }
    }
  }
}
