export const sendEmailUrl = '/notificacao/corporativo/v1/email'

export interface SendEmailRequest {
  emailDestinatario: string
  assunto: string
  mensagem: string
  emailType: string
  nomeDestinatario?: string
  isHtml: boolean
  copiaOcultaPara?: string
}

export function buildSendEmailRequest(email: string, emailSubject: string, emailContent: string) {
  const request: SendEmailRequest = {
    emailDestinatario: email,
    assunto: emailSubject,
    mensagem: emailContent,
    emailType: 'Mail',
    isHtml: false
  }
  return request
}
