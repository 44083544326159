import { hasWindow } from 'utils/browser'

export enum EventKind {
  click = 'click'
}

export interface EventParams {
  event_category: string
  event_label: string
  value?: number
}

export const sendEvent = (eventKind: EventKind, eventParams: EventParams) => {
  console.log(
    `Event sent
       kind: ${eventKind}
       params: ${JSON.stringify(eventParams)}`
  )

  // https://developers.facebook.com/docs/reference/javascript/FB.AppEvents.LogEvent/
  if (hasWindow() && (window as any).FB)
    (window as any).FB.AppEvents.logEvent(eventKind, null, eventParams)

  // https://developers.google.com/gtagjs/devguide/configure?hl=pt-br
  if (hasWindow() && (window as any).gtag) (window as any).gtag('event', eventKind, eventParams)

  // https://developers.google.com/analytics/devguides/collection/analyticsjs/sending-hits?hl=pt-br
  if (hasWindow() && (window as any).ga) (window as any).ga('send', 'event', eventKind, eventParams)
}
