import axios from 'axios'

interface GrantCodeDTO {
  redirect_uri: string
}

export class GrantCodeDataSource {
  private fleuryClientIdSensedia: string
  private fleuryBaseUrl: string

  constructor(fleuryBaseUrl: string, fleuryClientIdSensedia: string) {
    this.fleuryBaseUrl = fleuryBaseUrl
    this.fleuryClientIdSensedia = fleuryClientIdSensedia
  }

  getGrantCode = async () => {
    const instance = axios.create({
      baseURL: this.fleuryBaseUrl
    })
    const response = await instance.post<GrantCodeDTO>(
      '/oauth/grant-code',
      {
        client_id: this.fleuryClientIdSensedia
      },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )

    const redirectUri = response.data.redirect_uri
    const code = redirectUri.split('?code=')[1]

    return code
  }
}
