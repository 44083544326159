import styled from 'styled-components'
import { Color, FontSize, InputPlaceholder, Spacing } from 'atomic'
import { FontWeight } from 'atomic/legacy/obj.constants'

interface InputLabelProps {
  filled: boolean
}

export const FloatingLabelChildren = styled.span``

export const FloatingLabelWrapperStyled = styled(InputPlaceholder)`
  position: absolute;
  top: ${(props: InputLabelProps) => (props.filled ? '4px' : '32px')};
  left: 0;
  transition: all 0.3s;
  font-weight: ${(props: InputLabelProps) => (props.filled ? FontWeight.SemiBold : FontWeight.Normal)};
  font-size: ${(props: InputLabelProps) => (props.filled ? FontSize.XXSmall : FontSize.Small)};
  color: ${(props: InputLabelProps) => (props.filled ? Color.GrayDark : Color.Gray)};

  ${FloatingLabelChildren}:focus-within ~ & {
    color: ${Color.GrayDark};
    font-weight: ${FontWeight.SemiBold};
    font-size: ${FontSize.XXSmall};
    top: 4px;
  }
`

export const FloatingLabelStyled = styled.div`
  position: relative;
  padding-top: ${Spacing.Medium};
`
