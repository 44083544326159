import { SEO } from '@app/components/legacy/mol.seo/seo.component'
import TitleWithBreadcrumbRow from '@app/components/org.title-with-breadcrumb-row/title-with-breadcrumb-row.component'
import { PageProps } from '@app/utils/local-types'
import { appPaths } from 'utils/path'
import { Link } from '@reach/router'
import LargeSeparatorRow from 'atomic/atm.large-separator-row/large-separator-row.component'
import { getFaqJsonLd } from '@root/src/components/legacy/mol.seo/build-faq-json-ld'
import IndexLayout from '@root/src/components/org.layout/layout.component'
import { CosmicjsInformacoesEstaticas, Query } from '@root/src/data/graphql/graphql-types'
import { Body, Col, Divisor, Grid, H2, H3, Row } from 'atomic'
import { graphql } from 'gatsby'
import React from 'react'
import { FeedbackForm } from './component/feedback-form.component'

export interface FaqDetailTemplateProps {
  slug: string
  otherQuestions: {
    question: string
    slug: string
  }[]
}

interface PageData {
  question: string
  answer: string
  wasThisInfoUseful: string
  feedbackEmail: string
  col2Title: string
  otherQuestions: {
    question: string
    slug: string
  }[]
}

class FaqDetailTemplate extends React.Component<PageProps<FaqDetailTemplateProps, Query>> {
  render() {
    const data = this.props.data.cosmicjsPerguntasFrequentes
    const staticData: CosmicjsInformacoesEstaticas = this.props.data.cosmicjsInformacoesEstaticas

    const breadcrumbDictionary = {}
    breadcrumbDictionary[`/${this.props.pageContext.slug}`] = data.title

    const pageData: PageData = {
      question: data.title,
      answer: data.metadata.resposta,
      wasThisInfoUseful: staticData.metadata.feedbackTitulo,
      feedbackEmail: staticData.metadata.feedbackEmail,
      col2Title: staticData.metadata.col2Titulo,
      otherQuestions: this.props.pageContext.otherQuestions
    }

    return (
      <IndexLayout location={this.props.location}>
        <Grid>
          <SEO
            jsonld={getFaqJsonLd([{ Question: pageData.question, Answer: pageData.answer }])}
            socialMedia={{
              canonicalUrl: `${this.props.data.site.siteMetadata.siteUrl}${this.props.location.pathname}${this.props.location.search}`,
              title: data.metadata.seo.titulo,
              image: data.metadata.seo.imagem.url,
              imageAlt: data.metadata.seo.imagemAlternativa,
              description: data.metadata.seo.descricao
            }}
          />
          <TitleWithBreadcrumbRow
            title={pageData.question}
            addtionalDictionary={breadcrumbDictionary}
          />
          <Row mb mt>
            <Col xs={12} md={7}>
              <Body>{pageData.answer}</Body>
              <LargeSeparatorRow />
              <FeedbackForm
                id="form-faq-feed-back"
                title={pageData.wasThisInfoUseful}
                pageCategory={appPaths.faq.name}
                pageSlug={this.props.pageContext.slug}
                emailTo={pageData.feedbackEmail}
                // emailTo={pageData.feedbackEmail}
              />
            </Col>
            <Col xs={12} md={3} mdOffset={2}>
              <H2 cell>{pageData.col2Title}</H2>
              {pageData.otherQuestions.map((item, index) => (
                <React.Fragment key={index}>
                  <Link to={item.slug}>
                    <H3>{item.question}</H3>
                    {index !== pageData.otherQuestions.length - 1 && <Divisor />}
                  </Link>
                </React.Fragment>
              ))}
            </Col>
          </Row>
          <LargeSeparatorRow />
        </Grid>
      </IndexLayout>
    )
  }
}

export default FaqDetailTemplate

export const query = graphql`
  query FaqDetailBySlug($slug: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }

    cosmicjsPerguntasFrequentes(slug: { eq: $slug }) {
      id
      title
      metadata {
        resposta
        seo {
          titulo
          descricao
          imagemAlternativa
          imagem {
            url
          }
        }
      }
    }

    cosmicjsInformacoesEstaticas(slug: { eq: "detalhe-da-pergunta" }) {
      metadata {
        col2Titulo
        feedbackTitulo
        feedbackEmail
      }
    }
  }
`
